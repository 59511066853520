import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styled from 'styled-components'

const About = ({ data }) => {
  return (
    <StyledComponent className={'about-img'}>
      <Container>
        <Row>
          <Col sm={12} lg={8} md={12}>
            <div className="about-img__left">
              <div className="about-img__left__img parallax-img">
                <img src={'/images/dynamic/home/1.jpg'} alt='' />
              </div>
              <div className="about-img__left__img parallax-img">
                <img src={'/images/dynamic/home/2.jpg'} alt='' />
              </div>
            </div>
          </Col>

          <Col
            className={'d-flex justify-content-between flex-column'}
            sm={12}
            lg={4}
            md={12}
          >
            <h1>LEXEN BRANDED FILM</h1>
            <ul>
              <li>
                At TidyTints, we use top-quality Lexen tint to ensure a
                high-grade finish and durability. Our competitive pricing of $30
                per window offers exceptional value compared to the typical
                $60-$80 charged by other shops.
              </li>
              <li>
                We back our work with a comprehensive 5-year warranty covering
                fading, peeling, and bubbling. Plus, our mobile services bring
                professional tinting directly to your location for added
                convenience.
              </li>
              <li>
                For more information or to schedule an appointment, contact
                Ricky at 754-274-1628. Choose TidyTints for reliable,
                affordable, and convenient window tinting.
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </StyledComponent>
  )
}

const StyledComponent = styled.section`
  background-color: #3c3c3b;
  position: relative;
  padding: 40px 0px;

  .about-img__left {
    position: relative;
    display: flex;
    gap: 20px;

    &__img {
      width: 50%;

      img {
        width: 100%; /* Ensure the image takes full width */
        height: auto; /* Maintain aspect ratio */
        object-fit: cover; /* Ensure it covers its container without distortion */
      }
    }

    .bottom-img {
      position: absolute;
      bottom: -200px;
      right: 0px;
      max-width: 70%;
    }
  }

  h1 {
    font-size: 30px;
    margin-bottom: 30px;
    color: #fff;
  }

  ul li {
    list-style: inside;
    margin-bottom: 20px;
    color: #fff;
    text-align: justify;

    &:nth-last-child(1) {
      margin-bottom: unset;
    }
  }

  p {
    &:not(:nth-last-of-type(1)) {
      margin-bottom: 20px;
    }
  }

  /* Tablet desktop :768px. */
  @media (min-width: 768px) and (max-width: 991px) {
    h1 {
      margin-top: 20px;
      line-height: 40px;
    }
  }

  /* Small devices like iPhone */
  @media (max-width: 767px) {
    h1 {
      font-size: 28px;
    }

    .about-img__left {
      flex-direction: column; /* Stack the images vertically */
      gap: 10px; /* Adjust gap for smaller screens */
      margin-bottom: 20px; /* Adjust margin to reduce the space */
      align-items: center; /* Center-align for small screens */

      &__img {
        width: 100%; /* Full width on mobile */
      }

      .bottom-img {
        max-width: 50%;
        bottom: -30px;
      }
    }

    ul li {
      font-size: 14px; /* Adjust text size for better readability */
      line-height: 1.6;
    }
  }
`


export default About

import React from 'react'
import { Accordion, Col, Container, Row } from 'react-bootstrap'
import reactHtmlParser from 'react-html-parser'
import { BsChevronDown } from 'react-icons/bs'
import styled from 'styled-components'
import Title from './Title'

const AccordionItem = ({ titleColor }) => {
  return (
    <StyledComponent titleColor={titleColor}>
      <Container>
        <Row>
          <Col>
            <Title
              color={'#fff'}
              margin={'0 0 60px 0'}
              text={'Benefits of Elite Tint\n'}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Accordion defaultActiveKey="0">
              <Accordion.Item key={0} eventKey={`${0}`}>
                <Accordion.Header>
                  {reactHtmlParser('HOW LONG WILL IT TAKE?\n')}
                  <span>
                    <BsChevronDown />
                  </span>
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    {reactHtmlParser(
                      'Most cars are completed in 60-90 minutes.\n' + '\n',
                    )}
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item key={1} eventKey={`${1}`}>
                <Accordion.Header>
                  {reactHtmlParser('HOW LONG WILL IT TAKE?\n')}
                  <span>
                    <BsChevronDown />
                  </span>
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    {reactHtmlParser(
                      'Most cars are completed in 60-90 minutes.\n' + '\n',
                    )}
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item key={2} eventKey={`${2}`}>
                <Accordion.Header>
                  {reactHtmlParser('HOW LONG WILL IT TAKE?\n')}
                  <span>
                    <BsChevronDown />
                  </span>
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    {reactHtmlParser(
                      'Most cars are completed in 60-90 minutes.\n' + '\n',
                    )}
                  </p>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Container>
    </StyledComponent>
  )
}
const StyledComponent = styled.section`
  background-color: #3c3c3b;
  padding: 120px 0;

  .accordion-item {
    &:nth-last-child(1) {
      margin-bottom: 0;
    }
  }

  .accordion-item:nth-of-type(1) h2 button {
    padding-top: unset;
  }

  .accordion-header {
    position: relative;
    margin: 0;

    button {
      border: none;
      background-color: transparent;
      padding-left: 0;
      color: #f8f8f9;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      padding-top: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid #f8f8f9;
      width: 100%;
      text-align: left;
      transition: all 0.4s ease;

      span {
        color: #ca0034 !important;
        background-color: #f8f8f9;
      }

      &.collapsed {
        color: #f8f8f9;
        border-color: rgba(248, 248, 249, 0.2);
        background: transparent;

        span {
          background-color: transparent;
          color: #f8f8f9 !important;

          svg {
            transform: rotate(0deg);
          }

          &:after {
            background-color: #f8f8f9;
          }
        }
      }
    }

    span {
      position: absolute;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      border: 1px solid #f8f8f9;
      color: #f8f8f9;
      background-color: #ffffff;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 16px;

      &:after {
        height: 0;
        width: 0;
        background-color: #f8f8f9;
        border-radius: 50%;
        opacity: 0;
        transition: all 0.4s ease;
        content: '';
        position: absolute;

        svg {
          color: #f8f8f9 !important;
        }
      }

      svg {
        font-size: 10px;
        z-index: 2;
        transform: rotate(180deg);
      }
    }

    &:hover {
      span {
        &:after {
          height: 100%;
          width: 100%;
          opacity: 1;
        }

        svg {
          color: #ca0034;
        }
      }

      button {
        color: #f8f8f9;
        border-color: rgba(193, 193, 193, 0.5);
      }
    }
  }

  .accordion-body {
    padding-top: 20px;
    padding-bottom: 20px;

    p {
      font-size: 16px;
      font-weight: 400 !important;
      line-height: 24px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }

    .custom-list {
      padding: 0;

      li {
        position: relative;
        padding-left: 20px;
        //padding-top: 20px;

        &:not(:last-child) {
          padding-bottom: 0px;
        }

        &:before {
          content: '';
          position: absolute;
          top: 12px;
          left: 0;
          margin-top: -5px;
          width: 10px;
          height: 10px;
          box-sizing: content-box;
          background-color: #c1c1c1;
        }
      }
    }

    h4 {
      font-size: 16px;
      line-height: 22px;
      color: rgba(34, 31, 31, 0.2);
      margin: 0 0 10px 0;
    }

    p,
    a {
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      color: #f8f8f9;
    }

    .contact-numbers {
      margin-top: 45px;

      ul {
        li {
          a {
            margin-bottom: 13px;
            display: flex;

            span {
              font-size: 26px;
              font-weight: bold;
              line-height: 32px;
              transition: 0.3s ease;
            }

            &:hover span {
              color: #f8f8f9;
            }
          }

          &:nth-last-of-type(1) {
            margin-bottom: 0;
          }
        }
      }
    }

    .map {
      margin-top: 45px;

      iframe {
        width: 100%;
        height: 350px;
      }
    }
  }

  .address__img {
    position: relative;
    padding-top: calc(530 / 530 * 100%);
  }

  @media (max-width: 768px) {
    .address__accordion {
      min-width: 100%;
    }

    .address__form {
      min-width: 100%;
      margin-top: 60px;
    }
  }

  @media (max-width: 767px) {
    //
    //.address__row {
    //  flex-direction: column-reverse;
    //}
    .address__accordion {
      margin-top: 60px;
    }

    .address__form {
      margin-top: 0;
      margin-bottom: 60px;
    }

    .contact-numbers {
      flex-wrap: wrap;

      .col-sm-4 {
        min-width: 50%;

        &:nth-last-child(1) {
          margin-top: 30px;
          padding-left: 0;
        }
      }
    }
  }
`
export default React.memo(AccordionItem)

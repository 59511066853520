import { motion } from 'framer-motion'
import React, { useEffect } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { apiEndPoints } from '../../api/network/apiEndPoints'
import { ApiParam } from '../../api/network/apiParams'
import { fetchAbout } from '../../api/redux/about'
import { PageAnimation } from '../../components/animations/PageAnimation'
import InnerBanner from '../../components/InnerBanner'
import Table from '../../components/Table'

const Pricing = ({ offset }) => {
  const dispatch = useDispatch()

  // api call
  useEffect(() => {
    let api_url = apiEndPoints.SECTIONS

    let api_params = {
      [ApiParam.TYPE]: 'slug',
      [ApiParam.VALUE]: 'about',
      [ApiParam.GET_SECTION]: 'yes',
      [ApiParam.IMAGE]: 'yes',
      [ApiParam.POST]: 'yes',
    }

    dispatch(fetchAbout([api_url, api_params]))
  }, [])

  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Pricing | Elite Tinting</title>
        <meta
          name="description"
          content="Discover competitive pricing for Elite Tinting's professional window tinting services for automotive, residential, commercial, and marine needs. Get a transparent overview of our services and find the perfect tinting solution for your budget."
        />
      </Helmet>

      <motion.div
        key={`1w83`}
        className="page-loader"
        exit="exit"
        animate="anim"
        variants={PageAnimation}
        initial="init"
      ></motion.div>

      <StyledComponent>
        <InnerBanner
          img={'/images/dynamic/pricing/pricing.jpg'}
          srcSm={'/images/dynamic/pricing/pricing.jpg'}
        />
        <Table />
      </StyledComponent>
    </HelmetProvider>
  )
}

const StyledComponent = styled.section``

export default Pricing

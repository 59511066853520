import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styled from 'styled-components'
import ServiceItem from '../ServiceItem'
import Title from '../Title'

const HomeServices = ({ data, dataContent }) => {
  return (
    <StyledComponent className={'pt-160 pb-160'}>
      <div className="services-area">
        <Container>
          <Row>
            <Col sm={6}>
              <div className="services-area__left">
                <Title fontSize={'60'} text={'Services'} />
              </div>
            </Col>
          </Row>
          <Row className={'services-area__wrap'}>
            <Col className={'mb-30'} key={1} lg={4} md={12}>
              <ServiceItem
                url={'/images/dynamic/home/automotive.jpg'}
                name={'AUTOMOTIVE TINTING'}
                desc={
                  'Enhance privacy and reduce heat with our premium automotive tinting.'
                }
                img={'/images/dynamic/home/automotive.jpg'}
                number={1}
              />
            </Col>
            <Col className={'mb-30'} key={1} lg={4} md={12}>
              <ServiceItem
                url={'/images/dynamic/home/automotive.jpg'}
                name={'RESIDENTIAL TINTING'}
                desc={
                  'Improve comfort and energy efficiency with our residential window tinting.'
                }
                img={'/images/dynamic/home/residential.jpg'}
                number={2}
              />
            </Col>
            <Col className={'mb-30'} key={1} lg={4} md={12}>
              <ServiceItem
                url={'/images/dynamic/home/automotive.jpg'}
                name={'COMMERCIAL TINTING'}
                desc={
                  'Protect your business from UV rays and increase privacy with our commercial tinting solutions.'
                }
                img={'/images/dynamic/home/commercial.jpg'}
                number={3}
              />
            </Col>
            <Col className={'mb-30'} key={1} lg={4} md={12}>
              <ServiceItem
                url={'/images/dynamic/home/marine.jpg'}
                name={'MARINE TINTING'}
                desc={
                  'Shield your boat from harmful UV rays and improve aesthetics with our marine tinting.'
                }
                img={'/images/dynamic/home/marine.jpg'}
                number={4}
              />
            </Col>
            <Col className={'mb-30'} key={1} lg={4} md={12}>
              <ServiceItem
                url={'/images/dynamic/home/automotive.jpg'}
                name={'AUTOMOTIVE ACCESSORIES TINTING'}
                desc={
                  'Add style and protection to your automotive accessories with our specialized tinting.'
                }
                img={'/images/dynamic/home/automotive-accessories.jpg'}
                number={5}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </StyledComponent>
  )
}

const StyledComponent = styled.section`
    padding: 200px 0px;
    overflow: hidden;

    h3 {
        line-height: 60px;
    }

    .services-area {
        &__right {
            overflow: hidden;

            h6 {
                overflow: hidden;
            }
        }

        &__wrap {
            padding-top: 60px;
            /* small mobile :320px. */
            @media (max-width: 767px) {
                padding-top: 40px;
            }
        }
    }

    /* Tablet desktop :768px. */
    @media (min-width: 768px) and (max-width: 991px) {
        padding: 60px 0px;
        .services-area {
            //&__left {
            //    padding-bottom: 40px;
            //}

            h2 {
                font-size: 40px;
                line-height: 40px;
            }

            &__wrap {
                display: flex;
                gap: 30px;
                padding-top: 0px;
            }
        }
    }

    /* small mobile :320px. */
    @media (max-width: 767px) {
      padding: 60px 0px;
        .services-area {
            &__wrap {
                display: flex;
                gap 30px;
                padding-top : 0px!important;
            }
        }
    }
`

export default React.memo(HomeServices)

import React from 'react'
import { Col, Row } from 'react-bootstrap'
import styled from 'styled-components'

const USP = ({ id, offset }) => {
  return (
    <StyledComponent id={id} offset={offset} className={'ministry '}>
      <div className={'ministry__container'}>
        <Row>
          <Col
            md={{ span: 4, offset: 1 }}
            className={'box d-flex flex-column justify-content-between'}
          >
            <div className="box-top">
              <img src="/images/static/logo.png" alt="" />
              <h3 className="split-up">
                If It’s Glass, <span>We Can Tint It!</span>
              </h3>
            </div>

            <div className="box-bottom hide-mobie">
              <h3 className="split-up">
                <span>Gift Cards,</span> Available
              </h3>
              <p>
                Need a last-minute gift idea? We can help! We have gift cards
                available! We carry most automotive accessories including
                WEATHERTECH, WESTIN, GO RHINO, EXTANG, BESTOP, and many more!
              </p>
            </div>
          </Col>
          <Col className={'right-list'} md={{ span: 5, offset: 1 }}>
            <ul>
              <li>
                <h5>
                  FOR YOUR <span>HOME</span>
                </h5>
                <p>
                  Let us help keep your home comfortable, protect interiors from
                  fading, and improve your home’s curb appeal.
                </p>
              </li>
              <li>
                <h5>
                  FOR YOUR <span>CAR</span>
                </h5>
                <p>
                  We help keep your car cool, and with our tint, you can block
                  99% of UV rays and customize a style that works best for you.
                </p>
              </li>
              <li>
                <h5>
                  FOR YOUR <span>BOAT</span>
                </h5>
                <p>
                  You can now enjoy year-round climate control, as well as clear
                  views for day or night navigation on the open water.
                </p>
              </li>
              <li>
                <h5>
                  FOR YOUR <span>BUSINESS</span>
                </h5>
                <p>
                  Our tint will help improve productivity, reduce energy usage,
                  and upgrade to a more professional exterior aesthetic.
                </p>
              </li>
            </ul>
            <div className="box-bottom show-mobile">
              <h3 className="split-up">
                <span>Gift Cards,</span> Available
              </h3>
              <p>
                Need a last-minute gift idea? We can help! We have gift cards
                available! We carry most automotive accessories including
                WEATHERTECH, WESTIN, GO RHINO, EXTANG, BESTOP, and many more!
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </StyledComponent>
  )
}

const StyledComponent = styled.section`
  position: relative;
  padding-left: ${props => props.offset + 'px'};
  overflow: hidden;

  .show-mobile {
    display: none;
  }

  .hide-mobile {
    display: block;
  }

  .box-bottom {
    p {
      color: #fff;
    }
  }

  .right-list {
    ul {
      li {
        margin-bottom: 30px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        padding-bottom: 30px;

        &:nth-last-child(1) {
          margin-bottom: unset;
          border-bottom: unset;
        }

        h5 {
          margin-bottom: 20px;

          //span {
          //    color: #FF3333;
          //}
        }

        h5,
        p {
          color: #fff;
        }
      }
    }
  }

  &:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 50%;
    bottom: 0;
    background-color: #ffffff;
  }

  .ministry__container {
    position: relative;
    z-index: 2;

    .customs-list {
      padding: 0;
      counter-reset: listItem;

      li {
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        position: relative;
        color: #ffffff;

        p {
          font-weight: 500;
          font-size: 20px;
          line-height: 28px;
          position: relative;
          color: #ffffff;
          margin: 0 !important;
        }

        padding-left: 62px;

        &:not(:last-child) {
          border-bottom: 1px solid rgba(217, 217, 217, 0.5);
          padding-bottom: 40px;
        }

        &:not(:first-child) {
          padding-top: 40px;
        }

        &:before {
          content: counter(listItem);
          counter-increment: listItem;
          position: absolute;
          top: 55px;
          left: 0;
          //transform: translate(-50%,-50%);
          margin-top: -15px;
          width: 10px;
          height: 10px;
          box-sizing: content-box;
        }

        &:first-child {
          &:before {
            top: 15px !important;
          }
        }
      }
    }

    .row {
      background-color: #3c3c3b;
      padding: 120px 0;
    }
  }

  img {
    height: 250px;
  }

  .col-sm-6 {
    &:not(:nth-last-of-type(1)) {
      margin-bottom: 100px;
    }
  }

  h3 {
    font-size: 44px;
    font-weight: 500;
    letter-spacing: -1px;
    line-height: 56px;
    margin-top: 20px;
    color: #ffffff;

    //span {
    //    color: #FF3333;
    //}
  }

  @media (min-width: 768px) and (max-width: 991px) {
    h3 {
      line-height: 40px;
    }
  }

  @media (max-width: 767px) {
    padding-left: unset;

    .show-mobile {
      display: block;
    }
    .hide-mobie {
      display: none;
    }

    h3 {
      font-size: 32px;
      line-height: 40px;
      margin-top: 30px;
      margin-bottom: 30px;
    }

    .ministry__container {
      padding-left: 0 !important;

      .row {
        margin-left: 0 !important;
        margin-right: 0 !important;
      }

      .col-sm-2,
      .col-sm-7 {
        min-width: 100%;
        margin: 0;
      }

      .col-sm-2 {
        padding: 0 30px !important;
        margin-bottom: 60px;

        img {
          height: 70px;
        }
      }

      .col-sm-6 {
        min-width: 100%;

        &:not(:nth-last-of-type(1)) {
          margin-bottom: 60px;
        }

        h4 {
          font-size: 60px;
          line-height: 60px;
          padding-bottom: 10px;
          margin-bottom: 20px;
          letter-spacing: -4px;

          &:after {
            width: 100%;
          }
        }
      }
    }
  }
`

export default USP

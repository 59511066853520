import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styled from 'styled-components'
import { Img } from '../Img'

const AccessoriesGallery = () => {
  return (
    <StyledComponent className={'accessories-gallery'}>
      <Container>
        <Row>
          <Col>
            <h1>Full Line of Automotive Accessories</h1>
          </Col>
        </Row>
        <Row>
          <Col sm={12} className={'accessories-gallery__title'} sm={12}>
            <h1>Best Seller</h1>
          </Col>
          <Col lg={4} md={6} className={'accessories-gallery__single'}>
            <div className="accessories-gallery__single__img">
              <a href={'#'} />
              <Img src={'/images/dynamic/auto-accessories/led-tinting.jpg'} />
            </div>
            <h2>LED Light Bullbs</h2>
          </Col>
          <Col lg={4} md={6} className={'accessories-gallery__single'}>
            <div className="accessories-gallery__single__img">
              <Img src={'/images/dynamic/auto-accessories/floor-mat.jpg'} />
            </div>
            <h2>Floor Mats</h2>
          </Col>
          <Col lg={4} md={6} className={'accessories-gallery__single'}>
            <div className="accessories-gallery__single__img">
              <Img src={'/images/dynamic/auto-accessories/Mud-Flaps.jpg'} />
            </div>
            <h2>Mud Flaps</h2>
          </Col>
          <Col lg={4} md={6} className={'accessories-gallery__single'}>
            <div className="accessories-gallery__single__img">
              <Img src={'/images/dynamic/auto-accessories/Rain-Guards.jpg'} />
            </div>
            <h2>Rain Guards</h2>
          </Col>
          <Col lg={4} md={6} className={'accessories-gallery__single'}>
            <div className="accessories-gallery__single__img">
              <Img src={'/images/dynamic/auto-accessories/wax.jpg'} />
            </div>
            <h2>Wax</h2>
          </Col>
        </Row>
      </Container>
    </StyledComponent>
  )
}

const StyledComponent = styled.section`
  padding: 120px 0;
  overflow: hidden;

  .accessories-gallery {
    &__title {
      h1 {
        font-size: 48px;
        font-weight: 400;
        margin: 40px 0px;
        text-align: center;
      }
    }

    &__single {
      position: relative;
      overflow: hidden;
      margin-bottom: 30px;

      &__img {
        position: relative;
        overflow: hidden;
        padding-top: calc(300 / 228 * 100%);

        a {
          position: absolute;
          content: '';
          height: 100%;
          width: 100%;
          z-index: 1;
          top: 0;
        }
      }

      h2 {
        position: absolute;
        content: '';
        inset: 0;
        font-size: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
      }
    }
  }

  @media (max-width: 767px) {
    padding: 60px 0;
    h1 {
      font-size: 30px;
    }
  }
`

export default AccessoriesGallery

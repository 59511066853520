import { gsap } from 'gsap'
import { ScrollSmoother } from 'gsap/ScrollSmoother'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { Route, Switch, useLocation } from 'react-router-dom'
import { ParallaxImg } from './components/animations/Parallax'
import Error from './pages/404'
import GlobalStyle from './styles/globalStyle'

// page imports
import { AnimatePresence } from 'framer-motion'
import { useEffect, useRef, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { SplitUp } from './components/animations/TextAnimation'
import FloatingIcon from './components/FloatingIcon'
import Footer from './components/Footer'
import Menu from './components/Menu'
import PageTransition from './components/PageTransition'
import TopArrow from './components/TopArrow'
import AutoAccessories from './pages/autoAccessories'
import Contact from './pages/contact'
import Disclaimer from './pages/disclaimer'
import EliteTinting from './pages/elitetinting'
import GalleryPage from './pages/galleryPage'
import Home from './pages/home'
import Pricing from './pages/pricing'

function App() {
  const [offset, setOffset] = useState('90')
  const location = useLocation()
  gsap.registerPlugin(ScrollTrigger, ScrollSmoother)

  // smooth scroll init
  const el = useRef();
  useEffect(() => {
    let smoother = ScrollSmoother.create({
      smooth: 2, // how long (in seconds) it takes to "catch up" to the native scroll position
      // effects: true // looks for data-speed and data-lag attributes on elements
      // speed: 2
    });
    ScrollTrigger.refresh();

    if (location.hash) {
      setTimeout(() => {
        smoother.scrollTo(location.hash, 3);
      }, 500);
    }
  }, [location, location.pathname, location.hash]);

  // canonical generate
  useEffect(() => {
    // Get the current URL from the router's full path
    const currentUrl =
      window.location.origin + location.pathname + location.search

    // Set the canonical link dynamically
    const link = document.createElement('link')
    link.rel = 'canonical'
    link.href = currentUrl
    document.head.appendChild(link)

    // Clean up the link when the component unmounts
    return () => {
      document.head.removeChild(link)
    }
  }, [location.pathname, location.search])

  useEffect(() => {
    const updateOffset = () => {
      if (window.innerWidth > 767) {
        const container = document.querySelector('.container')
        if (container) {
          setOffset(container.offsetLeft + 15)
        }
      }
    }

    // Add an event listener for the DOMContentLoaded event
    document.addEventListener('DOMContentLoaded', updateOffset)

    // Add event listener to update offset on window resize
    window.addEventListener('resize', updateOffset)
    window.addEventListener('load', updateOffset)

    return () => {
      document.removeEventListener('DOMContentLoaded', updateOffset)
      window.removeEventListener('resize', updateOffset)
      window.removeEventListener('load', updateOffset)
    }
  }, [])


  // route change scroll top & page class
  useEffect(() => {
    if (location.pathname === '/') {
      document.body.classList.add('in-home-page')
    } else {
      document.body.classList.remove('in-home-page')
    }

    window.scroll(0, 0)

    const footerForm = document.querySelector('.footer-form')
    if (footerForm) {
      if (location.pathname.startsWith('/projects')) {
        footerForm.classList.add('bg-height')
      } else {
        footerForm.classList.remove('bg-height')
      }
    }

    if (location.pathname.startsWith('/blog/')) {
      document.body.classList.add('on-blog')
    } else {
      document.body.classList.remove('on-blog')
    }
  }, [location.pathname])

  ParallaxImg()
  SplitUp()

  return (
    <>
      {/*form submission loader*/}
      <GlobalStyle />
      {/*page transition */}
      <ToastContainer
        position="top-right"
        autoClose={4000}
        closeOnClick
        hideProgressBar={true}
      />
      <Menu />
      <PageTransition />
      <TopArrow offset={offset} />
      {location.pathname === "/" && <FloatingIcon />}

      <div className="App" ref={el} id="smooth-wrapper">
        <div id="smooth-content">
          <AnimatePresence exitBeforeEnter>
            <Switch location={location} key={'location.pathname'}>
              <Route exact path="/">
                <Home offset={offset} />
              </Route>
              <Route exact path="/elite-tinting">
                <EliteTinting offset={offset} />
              </Route>
              <Route exact path="/auto-accessories">
                <AutoAccessories offset={offset} />
              </Route>
              <Route exact path="/gallery">
                <GalleryPage offset={offset} />
              </Route>
              <Route exact path="/pricing">
                <Pricing offset={offset} />
              </Route>
              <Route exact path="/disclaimer">
                <Disclaimer offset={offset} />
              </Route>
              <Route exact path="/contact">
                <Contact offset={offset} />
              </Route>
              <Route component={Error} />
            </Switch>
          </AnimatePresence>
          <Footer />
        </div>
      </div>
    </>
  )
}

export default App

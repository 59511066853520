// pages/disclaimer.js
import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import styled from 'styled-components'
import { text } from '../../styles/globalStyleVars'

const DisclaimerText = () => {
  return (
    <StyleDisclaimerText>
      <Container className="mt-5">
        <Row className="justify-content-center">
          <Col md={10} lg={8}>
            <Card className="p-4 shadow-sm border-0">
              <Card.Body>
                <h1>Disclaimer</h1>
                <Card.Text style={{ lineHeight: '1.7' }}>
                  <h5 className="fw-bold">Property Waiver:</h5>
                  <p>
                    Julien Window Tinting takes reasonable precautions to
                    prevent possible damage to vehicles when performing our
                    services. I understand and agree that Julien Window Tinting
                    will not be held responsible for damages caused due to the
                    age of the vehicle. I understand that due to manufacturing
                    processes, vehicles with defrost/antenna lines can peel off
                    in part or in whole when removing tint.
                  </p>
                  <p>
                    I agree that I have had a reasonable opportunity to read and
                    fully understand this waiver, and I had the opportunity to
                    ask any and all questions about this waiver or the process
                    of window tinting and removal before signing this waiver. I
                    indemnify and hold harmless Julien Window Tinting against
                    any losses, liabilities, costs, claims, actions, demands or
                    expenses which may be incurred, or which may be made against
                    it as a result of or in connection with this agreement or
                    said services. I have signed this waiver freely, knowingly,
                    and voluntarily.
                  </p>
                  <h5 className="fw-bold mt-4">
                    Acknowledgement of Understanding:
                  </h5>
                  <p>
                    I acknowledge and understand that any tinting materials
                    added to the window after manufacture of the vehicles must
                    allow a VLT (Visible Light Transmittance) through the window
                    of at least 35% according to Maryland Transportation Article
                    22-406(i). I also acknowledge and understand the following:
                  </p>
                  <ul>
                    <li>
                      Windshield: Non-Reflective 35% VLT is allowed on the AS-1
                      or top 5 inches of the windshield.
                    </li>
                    <li>
                      Front Side Windows: Must allow more than 35% of light in.
                    </li>
                    <li>
                      Back Side Windows: Must allow more than 35% of light in.
                    </li>
                    <li>Rear Windows: Must allow more than 35% of light in.</li>
                  </ul>
                  <p>For Trucks, SUVs, and Vans (Class E or M vehicles):</p>
                  <ul>
                    <li>
                      Windshield: Non-Reflective 35% VLT is allowed on the AS-1
                      or top 5 inches of the windshield.
                    </li>
                    <li>
                      Front Side Windows: Must allow more than 35% of light in.
                    </li>
                    <li>Back Side Windows: Any darkness can be used.</li>
                    <li>Rear Windows: Any darkness can be used.</li>
                  </ul>
                  <p>
                    Accordingly, Maryland COMAR 29.02.01.11 states that
                    post-manufacture window tint shall not be mirrored, one-way
                    vision, sparkling effect, red, yellow, amber color tint or
                    any tint which changes to a prohibited type. I have had a
                    reasonable opportunity to read and understand this law and
                    how it applies to my vehicle.
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </StyleDisclaimerText>
  )
}

const StyleDisclaimerText = styled.section`
    h1 {
        font-size: 40px;
        margin-bottom: 20px;
        text-align: center;
    }

    h5 {
        color: ${text}; /* Use the hover color for headings */
        margin-bottom: 30px;
        font-weight: bold;
    }

    ul {
        list-style-type: disc;
        padding-left: 20px;
    }

    ul li {
        margin-bottom: 0.5rem;
    }

    a {
        color: #00a651; /* Match the company’s theme */
    }

    a:hover {
        text-decoration: underline;
    }

    @media (max-width: 767px) {
        h1 {
            font-size: 28px;
            text-align: left;
        }
    }

`

export default DisclaimerText

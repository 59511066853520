import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import styled from 'styled-components'
import AccessoriesGallery from '../../components/accessories/AccessoriesGallery'
import GetInTouch from '../../components/contact/GetInTouch'
import Note from '../../components/contact/Note'
import CTAV3 from '../../components/CTA-v3'
import InnerBanner from '../../components/InnerBanner'

const AutoAccessories = () => {


  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Auto Accessories | Elite Tint</title>
        <meta
          name="description"
          content="Explore Elite Tint's premium auto accessories, including window tinting, paint protection, and more. Enhance your vehicle's style, comfort, and performance with our top-quality products and services."
        />
      </Helmet>

      <StyledComponent>
        <InnerBanner
          img={'/images/dynamic/auto-accessories/banner.jpg'}
          srcSm={'/images/dynamic/auto-accessories/banner.jpg'}
          title={'Auto Accessories'}
        />
        <AccessoriesGallery />
        <CTAV3
          hideCard
          bgImage={'/images/dynamic/auto-accessories/call-to-purchase-2.jpg'}
          title={
            'We have inventory for thousands of accessories. Call us today to find the product or brand that will work for you.'
          }
        />
        <GetInTouch />
        <Note
          title={'Connect with Us\n'}
          desc={
            'Elite Window Tinting in North East, MD, can be reached at <a href="tel:4102878468">Call 410-287-8468</a>\n to learn more.'
          }
        />
      </StyledComponent>
    </HelmetProvider>
  )
}

const StyledComponent = styled.section``

export default AutoAccessories

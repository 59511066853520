import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styled from 'styled-components'
import { hover } from '../../styles/globalStyleVars'
import CallToAction from '../CallToAction'

const Info = () => {
  return (
    <StyledInfo>
      <Container>
        <Row>
          <Col lg={8} md={12}>
            <CallToAction
              title={'Contact Our Team Today\n'}
              desc={
                'Julien Window Tinting in North East, MD, can be reached at 410-287-8468 to learn more or to schedule an appointment.\n' +
                '\n'
              }
            />
          </Col>
          <Col lg={4} md={12}>
            <div className="hours-container">
              <h2>Hours of Operation</h2>
              <div className="hours">
                <div className="day">
                  <span>Monday</span>
                  <span>Closed</span>
                </div>
                <div className="day">
                  <span>Tue - Fri</span>
                  <span>9:00 am - 6:00 pm</span>
                </div>
                <div className="day">
                  <span>Saturday</span>
                  <span>9:00 am - 1:00 pm</span>
                </div>
                <div className="day">
                  <span>Sunday</span>
                  <span>Closed</span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </StyledInfo>
  )
}

const StyledInfo = styled.section`
  padding: 120px 0px;
  background-color: #3c3c3b;
  color: #fff;

  p {
    color: #fff;
  }

  .hours-container {
    background: ${hover}; /* Dark background with opacity */
    color: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    @media (min-width: 768px) and (max-width: 991px) {
      margin-top: 60px;
    }
    @media (max-width: 767px) {
      margin-top: 60px;
    }
  }

  .hours-container h2 {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
  }

  .hours {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .day {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
  }

  .day span {
    display: block;
  }
`
export default Info

import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styled from 'styled-components'
import Title from '../Title'

const Benefits = () => {
  return (
    <StyledComponent className={'benefits-area'}>
      <Container>
        <Row>
          <Col className={'text-center'}>
            <Title margin={'0 0 60px 0'} text={'Benefits of Elite Tint\n'} />
          </Col>
        </Row>
        <Row className={'benefits-area-items'}>
          <Col className={'benefits-item text-center'} sm={3}>
            <img src="/images/static/INCREASE-COMFORT.svg" alt="increase" />
            <h4>INCREASE COMFORT</h4>
          </Col>
          <Col className={'benefits-item'} sm={3}>
            <img src="/images/static/OFFERS-UV-PROTECTION.svg" alt="offsers" />
            <h4>OFFERS UV PROTECTION</h4>
          </Col>
          <Col className={'benefits-item'} sm={3}>
            <img src="/images/static/MINIMIZE-FADING.svg" alt="fading" />
            <h4>MINIMIZE FADING</h4>
          </Col>
          <Col className={'benefits-item'} sm={3}>
            <img src="/images/static/REDUCE-GLARE.svg" alt="reduce" />
            <h4>REDUCE GLARE</h4>
          </Col>
          <Col className={'benefits-item'} sm={3}>
            <img src="/images/static/HELPS-WITH-PRIVACY.svg" alt="privacy" />
            <h4>HELPS WITH PRIVACY</h4>
          </Col>
          <Col className={'benefits-item'} sm={3}>
            <img src="/images/static/CREATES-A-CUSTOM-LOOK.svg" alt="look" />
            <h4>CREATES A CUSTOM LOOK</h4>
          </Col>
          <Col className={'benefits-item'} sm={3}>
            <img src="/images/static/INCREASES-SAFETY.svg" alt="look" />
            <h4>INCREASES SAFETY</h4>
          </Col>
          <Col className={'benefits-item'} sm={3}>
            <img src="/images/static/HELPS-SAVE-ON-FUEL.svg" alt="fuel" />
            <h4>HELPS SAVE ON FUEL</h4>
          </Col>
        </Row>
      </Container>
    </StyledComponent>
  )
}

const StyledComponent = styled.section`
  padding: 60px 0;
  background-color: aliceblue;

  .benefits-area-items {
    padding: 30px;
  }

  .benefits-item {
    text-align: center;
    margin-bottom: 40px;
    img {
      margin-bottom: 20px;
    }
  }
`

export default Benefits

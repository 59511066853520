import { motion } from 'framer-motion'
import React, { useEffect } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { apiEndPoints } from '../../api/network/apiEndPoints'
import { ApiParam } from '../../api/network/apiParams'
import { fetchAbout } from '../../api/redux/about'
import { PageAnimation } from '../../components/animations/PageAnimation'
import CallToAction from '../../components/CallToAction'
import Note from '../../components/contact/Note'
import Gallery from '../../components/Gallery'
import InnerBanner from '../../components/InnerBanner'
import Video from '../../components/project/Video'

const GalleryPage = ({ offset }) => {
  const dispatch = useDispatch()

  // api call
  useEffect(() => {
    let api_url = apiEndPoints.SECTIONS

    let api_params = {
      [ApiParam.TYPE]: 'slug',
      [ApiParam.VALUE]: 'about',
      [ApiParam.GET_SECTION]: 'yes',
      [ApiParam.IMAGE]: 'yes',
      [ApiParam.POST]: 'yes',
    }

    dispatch(fetchAbout([api_url, api_params]))
  }, [])

  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Gallery | Elite Tinting</title>
        <meta
          name="description"
          content="Browse the Elite Tinting gallery to see our expert window tinting work for cars, homes, businesses, and boats. Explore the transformations and quality results we deliver to our clients."
        />
      </Helmet>

      <motion.div
        key={`1w83`}
        className="page-loader"
        exit="exit"
        animate="anim"
        variants={PageAnimation}
        initial="init"
      ></motion.div>

      <StyledComponent>
        <InnerBanner
          img={'/images/dynamic/gallery/gallery-banner.jpg'}
          srcSm={'/images/dynamic/gallery/gallery-banner.jpg'}
          title={'Auto Accessories'}
        />
        <CallToAction
          padding
          title={'Check Out the Work We’ve Done\n'}
          desc={
            'Elite Window Tinting in North East, MD, invites you to view our photo gallery of our past work.\n' +
            '\n'
          }
        />
        <Gallery />
        <Video />
        <Note
          title={'Call Today'}
          desc={
            'Julien Window Tinting in North East, MD, can be reached at <a href="tel:4102878468">Call 410-287-8468</a>\n to learn more or to schedule an appointment.\n' +
            '\n'
          }
        />
      </StyledComponent>
    </HelmetProvider>
  )
}

const StyledComponent = styled.section``

export default GalleryPage

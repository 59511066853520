import { motion } from 'framer-motion'
import React, { useEffect } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { apiEndPoints } from '../../api/network/apiEndPoints'
import { ApiParam } from '../../api/network/apiParams'
import { fetchAbout } from '../../api/redux/about'
import Accordion from '../../components/Accordion'
import { PageAnimation } from '../../components/animations/PageAnimation'
import Note from '../../components/contact/Note'
import Benefits from '../../components/eliteTinting/Benefits'
import GetInTouchElite from '../../components/eliteTinting/GetInTouchElite'
import InnerBanner from '../../components/InnerBanner'
import WhoWeAre from '../../components/WhoWeAre'
import ZigZag from '../../components/ZigZag'

const EliteTinting = ({ offset }) => {
  const dispatch = useDispatch()

  // api call
  useEffect(() => {
    let api_url = apiEndPoints.SECTIONS

    let api_params = {
      [ApiParam.TYPE]: 'slug',
      [ApiParam.VALUE]: 'about',
      [ApiParam.GET_SECTION]: 'yes',
      [ApiParam.IMAGE]: 'yes',
      [ApiParam.POST]: 'yes',
    }

    dispatch(fetchAbout([api_url, api_params]))
  }, [])

  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>About | Elite Tint</title>
        <meta
          name="description"
          content="Learn more about Elite Tint, your trusted provider for professional window tinting services for automotive, residential, commercial, and marine applications. Discover our commitment to quality, customer satisfaction, and expert installation."
        />
      </Helmet>

      <motion.div
        key={`1w83`}
        className="page-loader"
        exit="exit"
        animate="anim"
        variants={PageAnimation}
        initial="init"
      ></motion.div>

      <StyledComponent>
        <InnerBanner
          img={'/images/dynamic/elite-tinting/elite-banner.jpg'}
          srcSm={'/images/dynamic/elite-tinting/elite-banner.jpg'}
        />
        <WhoWeAre
          id={'automotive'}
          title={'Experienced Elite Tinting'}
          desc={
            'Julien Window Tinting in North East, MD uses a darker film that absorbs more heat including standard Galaxie window film, ceramic VortexIR window film and Solstice. Our tint offers superior quality and exceptional performance which enhances your driving experience and protects you from the sun’s heat and harmful UV rays. Our film leads the industry on clarity, which helps with unaltered visibility and safe driving, making Galaxie the best window film for looking and keeping cool on a hot and sunny day. Julien Window Tinting in North East, MD, can be reached at 410-287-8468 for experienced window tinting.\n' +
            '\n'
          }
          img={'/images/dynamic/elite-tinting/elite-about.jpg'}
        />
        <Benefits />
        <ZigZag
          id={'residential-commercial'}
          marineId={'marine'}
          offset={offset}
        />
        <Accordion />
        <GetInTouchElite />
        <Note
          title={'Note'}
          desc={
            'Julien Window Tinting in North East, MD, can be reached at <a href="tel:4102878468">Call 410-287-8468</a>\n to learn more or to schedule a window tinting appointment.'
          }
        />
      </StyledComponent>
    </HelmetProvider>
  )
}

const StyledComponent = styled.section``

export default EliteTinting

import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import ReactHtmlParser from 'react-html-parser'
import styled from 'styled-components'
import { Img } from './Img'

const ZigZag = ({ offset, id, marineId }) => {
  return (
    <StyledComponent id={id} className={'pt-160 pb-160'} offset={offset}>
      <div className="zigzag-area">
        <div className="zigzag-area__single">
          <Container fluid p-0>
            <Row id={id}>
              <Col className={'p-0  zigzag-area__single__wrap'} lg={5} md={12}>
                <div className="zigzag-area__single__wrap__text">
                  {/*<span>Pioneering Innovation for a Sustainable Tomorrow</span>*/}
                  <h4>Residential & Commercial Tinting</h4>
                  <p className={'split-up'}>
                    {ReactHtmlParser(
                      'For homes and businesses, it’s important to use property window film, to maximize your home’s efficiency. Our film of choice is a more mirrored look, and is more reflective, which means more rays bounce off your home and decreases your utility bills. Our window film will help your home or office remain at a steady temperature. Our film also helps keep your home safe from onlookers, increases your property’s value, makes your windows easier to clean, and more. To learn more, give our team a call today.',
                    )}
                  </p>
                </div>
              </Col>

              <Col className={'p-0'} lg={{ span: 6, offset: 1 }} md={12}>
                <div className="zigzag-area__single__img reveal">
                  <Img
                    src={'/images/dynamic/elite-tinting/modern-residential.jpg'}
                  />
                </div>
              </Col>
            </Row>
            <Row id={marineId}>
              <Col className={'p-0  zigzag-area__single__wrap'} lg={5} md={12}>
                <div className="zigzag-area__single__wrap__text">
                  {/*<span>Pioneering Innovation for a Sustainable Tomorrow</span>*/}
                  <h4>Marine</h4>
                  <p>
                    {ReactHtmlParser(
                      'It’s more important having your boat’s windows tinted to prevent the sun from blinding you while you’re on the water. Our tint can block up to 99% of harmful UV rays. Your boat is a big investment, our tint will also help protect the interior surfaces of your boat. Keep comfortable while on the water, and give our professional marine tint installers a call today.',
                    )}
                  </p>
                </div>
              </Col>

              <Col className={'p-0'} lg={{ span: 6, offset: 1 }} md={12}>
                <div className="zigzag-area__single__img reveal">
                  <Img src={'/images/dynamic/elite-tinting/marine.jpg'} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </StyledComponent>
  )
}

const StyledComponent = styled.section`
  .zigzag-area {
    &__single {
      &__wrap {
        display: flex;
        align-items: center;

        &__text {
          padding-left: ${props => (props.offset ? props.offset : '')}px;

          span {
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            color: #9cabb9;
          }

          h4 {
            margin-top: 20px;
            margin-bottom: 40px;
            font-weight: 500;
            line-height: 48px;
            font-size: 35px;

            span {
              color: #ca0034;
              font-size: 36px;
              font-weight: 400;
              line-height: 40px;
            }
          }

          p {
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            margin-bottom: 20px;
            text-align: justify;
          }
        }
      }

      &__img {
        position: relative;
        padding-top: calc(560 / 668 * 100%);
      }
    }
  }

  .row:nth-child(even) {
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 160px 0;

    .offset-md-1 {
      margin-left: unset;
    }

    .zigzag-area {
      &__single {
        &__wrap {
          &__text {
            padding-left: unset;
            padding-right: ${props => (props.offset ? props.offset : '')}px;
          }
        }

        &__img {
          position: relative;
          padding-top: calc(560 / 668 * 100%);
          margin-right: 30px;
        }
      }
    }
  }

  @media (min-width: 992px) {
    .row:nth-child(even) .offset-lg-1 {
      margin-left: 0px !important;
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .zigzag-area__single__wrap__text {
      padding-left: 15px;
      padding-right: 15px;
    }

    .row:nth-child(even) {
      padding: 20px 0px;
      .zigzag-area__single__wrap__text {
        padding-right: 15px;
        padding-left: 15px;
      }
    }
  }

  /* small mobile :320px. */
  @media (max-width: 767px) {
    .row:nth-child(odd) {
      flex-direction: column-reverse;

      .zigzag-area__single__wrap__text {
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 40px;
      }
    }

    .row:nth-child(even) {
      flex-direction: column-reverse;
      padding: 60px 0;

      .zigzag-area__single__wrap__text {
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 40px;
      }
    }

    .row:nth-child(2n) .zigzag-area__single__img {
      margin-right: 0px;
    }
  }
`

export default React.memo(ZigZag)

import { gsap } from 'gsap'
import React, { useEffect, useRef, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import styled from 'styled-components'
import { Autoplay, Navigation, Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/effect-fade'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import { Swiper, SwiperSlide } from 'swiper/react'
import { hover } from '../../styles/globalStyleVars'
import { Img } from '../home/ImgP'

const Banner = ({ data, offset, feature_data, windowWidth }) => {
  const banner_data = data?.posts?.list || []
  const feature_box = feature_data?.posts?.list
  // const slider_data = data?.posts?.list?.[0]?.find(f => f?.images?.banner === 'on') ;

  const swiperRef = useRef(null)
  const swiperRefTwo = useRef(null)

  // handle slider progress
  const handleProgress = swiper => {
    let interleaveOffset = 0.5
    for (let i = 0; i < swiper.slides.length; i++) {
      let slideProgress = swiper.slides[i].progress
      let innerOffset = swiper.width * interleaveOffset
      let innerTranslate = slideProgress * innerOffset
      swiper.slides[i].querySelector('.global-image').style.transform =
        'translate3d(' + innerTranslate + 'px, 0, 0)'
    }
  }

  // hand touch move not required this slider
  const handleTouchStart = swiper => {
    for (let i = 0; i < swiper.slides.length; i++) {
      swiper.slides[i].style.transition = ''
    }
  }

  // handle image transition on change
  const handleSetTransition = (swiper, speed) => {
    for (let i = 0; i < swiper.slides.length; i++) {
      swiper.slides[i].style.transition = `${speed}ms`
      swiper.slides[i].querySelector(
        '.global-image',
      ).style.transition = `${speed}ms`
    }
  }

  // handle pagination
  let pagination_title = [
    'Financial freedom at the palm of your hands',
    'Investing in growth to open new big opportunities',
    'Financial freedom at the palm of your hands',
    'Investing in growth to open new big opportunities',
  ]
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return (
        '<div class="' +
        className +
        '"><span>' +
        pagination_title[index] +
        '</span></div>'
      )
    },
  }
  const [activeSlideIndex, setActiveSlideIndex] = useState(0)

  const handleButtonClickPagination = index => {
    if (swiperRef.current) {
      setActiveSlideIndex(index)
      swiperRef.current.swiper.slideToLoop(index)
    }
  }

  useEffect(() => {
    const swiper = swiperRef.current
    const autoplayDelay = 5000 // Delay in milliseconds before autoplaying
    const autoplayTimeout = setTimeout(() => {
      if (swiper && swiper.autoplay) {
        swiper.autoplay.start()
      }
    }, autoplayDelay)
    return () => {
      clearTimeout(autoplayTimeout)
    }
  }, [])

  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = e => {
    setShow(true)
  }

  const [isModalVisible, setIsModalVisible] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setIsModalVisible(true)
    }, 1500)
  }, [isModalVisible])

  const location = useLocation()

  useEffect(() => {
    // Use GSAP to animate opacity from 0 to 1
    gsap.to('.hide', {
      alpha: 1,
      visibility: 'visible',
      display: 'block',
      duration: 0.5,
      delay: 1,
    })
    gsap.to('.hide-title', {
      alpha: 1,
      visibility: 'visible',
      display: 'block',
      duration: 0.5,
      delay: 1,
    })
  }, [location.pathname, data]) // Run this effect only once when the component mounts

  useEffect(() => {
    const sectionTop = document.querySelector('.home-banner').offsetTop
    window.addEventListener('scroll', () => {
      if (window.pageYOffset >= sectionTop) {
        // swiperScroll.current.autoplay.stop()
        setTimeout(() => {
          swiperRef?.current?.swiper?.autoplay.start()
        }, 300)
      }
    })
  }, [banner_data])
  return (
    <>
      <StyledBanner offset={offset} className={'home-banner '}>
        <Swiper
          ref={swiperRef}
          spaceBetween={0}
          loop={true}
          autoplay={true}
          speed="2500"
          onProgress={handleProgress}
          touchStart={handleTouchStart}
          onSetTransition={handleSetTransition}
          grabCursor={false}
          lazy={true}
          watchSlidesProgress={true}
          mousewheelControl={true}
          keyboardControl={true}
          navigation={{
            prevEl: '#banner-prev',
            nextEl: '#banner-next',
          }}
          pagination={pagination}
          modules={[Autoplay, Pagination, Navigation]}
          sName="mySwiper main-swiper"
        >
          <SwiperSlide key={0}>
            <div className="slide-inner">
              <div className="desktop">
                {windowWidth > 767 ? (
                  <Img
                    alt={'Shanta Securities Limited'}
                    src={'/images/dynamic/home/banner-slider-2.jpg'}
                  />
                ) : (
                  <Img
                    alt={'Shanta Securities Limited'}
                    src={'/images/dynamic/home/banner-slider-2.jpg'}
                  />
                )}
              </div>

              {/*<div className="mobile">*/}
              {/*</div>*/}

              <Container>
                <Row>
                  <Col>
                    <div className="slide-inner__info">
                      <div className="hide-title">
                        <h1>Enhance Your Ride</h1>

                        <h2>The Benefits of Professional Car Window Tinting</h2>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </SwiperSlide>
          <SwiperSlide key={1}>
            <div className="slide-inner">
              <div className="desktop">
                {windowWidth > 767 ? (
                  <Img
                    alt={'Shanta Securities Limited'}
                    src={'/images/dynamic/home/banner-slider.jpg'}
                  />
                ) : (
                  <Img
                    alt={'Shanta Securities Limited'}
                    src={'/images/dynamic/home/banner-slider.jpg'}
                  />
                )}
              </div>

              {/*<div className="mobile">*/}
              {/*</div>*/}

              <Container>
                <Row>
                  <Col>
                    <div className="slide-inner__info">
                      <div className="hide-title">
                        <h1>Drive in Style and Comfort</h1>

                        <h2>Why Car Window Tinting is a Must</h2>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </SwiperSlide>
        </Swiper>

        {/*next prev navigation*/}
        <div className="navigation">
          <Container>
            <Row>
              <Col>
                <ul>
                  <li id={'banner-next'}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="44"
                      height="44"
                      viewBox="0 0 44 44"
                    >
                      <g id="Next" transform="translate(16429 -2014)">
                        <g
                          id="Rectangle_492"
                          data-name="Rectangle 492"
                          transform="translate(-16429 2014)"
                          fill="none"
                          stroke="#fff"
                          strokeWidth="1"
                          opacity="0.5"
                        >
                          <rect width="44" height="44" rx="22" stroke="none" />
                          <rect
                            x="0.5"
                            y="0.5"
                            width="43"
                            height="43"
                            rx="21.5"
                            fill="none"
                          />
                        </g>
                        <g
                          id="Group_4824"
                          data-name="Group 4824"
                          transform="translate(-17065.5 434.5)"
                        >
                          <g
                            id="Group_4823"
                            data-name="Group 4823"
                            transform="translate(11 4)"
                          >
                            <line
                              id="Line_9"
                              data-name="Line 9"
                              x2="5"
                              y2="5"
                              transform="translate(647.5 1592.5)"
                              fill="none"
                              stroke="#fff"
                              strokeLinecap="round"
                              strokeWidth="1"
                            />
                            <line
                              id="Line_10"
                              data-name="Line 10"
                              x1="5"
                              y2="5"
                              transform="translate(647.5 1597.5)"
                              fill="none"
                              stroke="#fff"
                              strokeLinecap="round"
                              strokeWidth="1"
                            />
                          </g>
                          <line
                            id="Line_11"
                            data-name="Line 11"
                            x2="10"
                            transform="translate(653.5 1601.5)"
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeWidth="1"
                          />
                        </g>
                        <g
                          id="Rectangle_5661"
                          data-name="Rectangle 5661"
                          transform="translate(-16429 2058) rotate(-90)"
                          fill="none"
                          stroke="#fff"
                          strokeWidth="1"
                          strokeDasharray="0"
                          opacity="0"
                        >
                          <rect width="44" height="44" rx="22" stroke="none" />
                          <rect
                            x="0.5"
                            y="0.5"
                            width="43"
                            height="43"
                            rx="21.5"
                            fill="none"
                          />
                        </g>
                      </g>
                    </svg>
                  </li>
                  <li id={'banner-prev'}>
                    <svg
                      id="Prev"
                      xmlns="http://www.w3.org/2000/svg"
                      width="44"
                      height="44"
                      viewBox="0 0 44 44"
                    >
                      <g
                        id="Rectangle_492"
                        data-name="Rectangle 492"
                        fill="none"
                        stroke="#fff"
                        strokeWidth="1"
                        opacity="0.5"
                      >
                        <rect width="44" height="44" rx="22" stroke="none" />
                        <rect
                          x="0.5"
                          y="0.5"
                          width="43"
                          height="43"
                          rx="21.5"
                          fill="none"
                        />
                      </g>
                      <g
                        id="Group_4824"
                        data-name="Group 4824"
                        transform="translate(17 17)"
                      >
                        <g id="Group_4823" data-name="Group 4823">
                          <line
                            id="Line_9"
                            data-name="Line 9"
                            x1="5"
                            y2="5"
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeWidth="1"
                          />
                          <line
                            id="Line_10"
                            data-name="Line 10"
                            x2="5"
                            y2="5"
                            transform="translate(0 5)"
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeWidth="1"
                          />
                        </g>
                        <line
                          id="Line_11"
                          data-name="Line 11"
                          x1="10"
                          transform="translate(0 5)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeWidth="1"
                        />
                      </g>
                      <g
                        id="Rectangle_5661"
                        data-name="Rectangle 5661"
                        transform="translate(0 44) rotate(-90)"
                        fill="none"
                        stroke="#fff"
                        strokeWidth="1"
                        strokeDasharray="1 140"
                        opacity="0"
                      >
                        <rect width="44" height="44" rx="22" stroke="none" />
                        <rect
                          x="0.5"
                          y="0.5"
                          width="43"
                          height="43"
                          rx="21.5"
                          fill="none"
                        />
                      </g>
                    </svg>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>
      </StyledBanner>
    </>
  )
}

const StyledBanner = styled.section`
  position: relative;
  opacity: 1 !important;
  .swiper,
  swiper-container {
    height: 100vh;
  }
  .swiper-button-prev,
  .swiper-button-next,
  .swiper-pagination,
  .mobile {
    display: none;
  }

  .swiper-slide {
    overflow: hidden;
  }

  .swiper-pagination {
    display: none;
  }

  .swiper-button-disabled {
    opacity: 0.5 !important;
  }

  .global-image {
    transition: clipPath 0.8s cubic-bezier(0.29, 0.73, 0.45, 1),
      border-color 3.6s linear,
      -webkit-clipPath 0.8s cubic-bezier(0.29, 0.73, 0.45, 1);
    will-change: clipPath;
    overflow: hidden;
    -webkit-clippath: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    clippath: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }

  .swiper-slide-active,
  .swiper-slide-visible {
    .global-image {
      clippath: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }

    .slide-inner {
      img {
        height: 120vh !important;

        @media (max-width: 767px) {
          height: 100% !important;
        }
      }
    }
  }

  //custom navigation

  .swipper-navigation-slider-custom {
    display: flex;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 200px;
    min-height: 75px;
    text-align: left;
    margin: 0 auto;
    padding-left: ${props => (props.offset ? props.offset : '90')}px;
    padding-right: ${props => (props.offset ? props.offset : '90')}px;
    justify-content: space-between;
    gap: 15px;
    flex-direction: row;

    .slide-inner-item {
      width: 100%;
      position: relative;
      background: transparent !important;
      padding: 20px 0px 0;
      cursor: pointer;

      @media (min-width: 1536px) {
        padding-right: 15px;
      }

      span {
        font-size: 20px;
        font-weight: 500;
        color: #ffffff;
        line-height: 28px;
      }

      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 4px;
        background: #978c21;
        width: 0;
        transition: 1s all cubic-bezier(0.25, 0.74, 0.22, 0.99);
        opacity: 0;
      }

      &:hover {
        // span {
        //   color: ${hover}
        // }

        &:after {
          width: 100%;
          opacity: 1;
        }
      }

      &:before {
        content: '';
        background: transparent !important;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: 99;
      }

      &.active {
        &:after {
          width: 100%;
          opacity: 1;
        }
      }
    }
  }

  //feature box

  .feature-box-container {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -110px;
    z-index: 999;
    width: calc(100% - 30px);
    margin: 0 auto;

    .col-md-3 {
      padding: 0;
    }

    .single-item-wrapper {
      background: white;
      border-right: 1px solid rgba(198, 198, 198, 0.5);
      height: 100%;

      &.last {
        background: #978c21;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: none !important;

        a {
          width: 100%;
          text-align: left;

          .content {
            align-items: center;
            justify-content: center;
          }

          svg {
            #Ellipse_601 {
              transition: 1s all cubic-bezier(0.25, 0.74, 0.22, 0.99);
            }
          }

          @keyframes dash {
            from {
              stroke-dashoffset: 0;
            }
            to {
              stroke-dashoffset: 550px;
            }
          }

          &:hover {
            //svg {
            //  #Ellipse_601 {
            //    opacity: 1;
            //    stroke-dasharray: 520px;
            //
            //  }
            //}
          }
        }
      }

      a {
        padding: 40px 40px 45px;
        display: block;
        height: 100%;

        .content {
          z-index: 4;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          flex-direction: column;
          width: 100%;
          height: 100%;

          p {
            color: #c6c6c6;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            margin-bottom: 40px;
          }

          h3 {
            margin: 0 0 0px;
            color: #3c3c3b;
            font-family: 'Avenir Heavy';
            font-size: 28px;
            letter-spacing: -1px;
            line-height: 36px;
            transition: 1s all cubic-bezier(0.25, 0.74, 0.22, 0.99);
            position: relative;
            display: flex;
            justify-content: space-between;
            width: 100%;
          }
        }

        svg {
          #Rectangle_5661 {
            transition: 1s all cubic-bezier(0.25, 0.74, 0.22, 0.99);
          }
        }

        &:hover {
          h3 {
            color: ${hover};
          }

          svg {
            #Rectangle_5661 {
              opacity: 1;
              stroke-dasharray: 140px;
            }
          }
        }
      }
    }
  }

  .slide-inner {
    position: relative;
    height: 120vh;

    .container {
      position: absolute;
      left: 0;
      right: 0;
      top: calc(50% - 10vh);
      transform: translateY(-50%);
      z-index: 2;
    }

    .global-image {
      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: 1;
        background: linear-gradient(
          to right,
          rgba(0, 0, 0, 0.4),
          rgba(0, 0, 0, 0)
        );
      }
    }

    &__info {
      position: relative;
      overflow: hidden;
      //transform: translateX(50%);
      //transition: all 2s cubic-bezier(.25, .74, .22, .99);
      //transition-delay: 1.2s;
      //opacity: 1;
      @media (min-width: 1024px) {
        width: 70%;
      }
      h2 {
        font-size: 60px;
        line-height: 70px;
        margin: 0 0 30px 0;
        font-weight: 500;
        color: white;
      }

      h1 {
        font-size: 60px;
        line-height: 70px;
        margin: 0 0 30px 0;
        font-weight: 500;
        color: white;
      }

      .dc-btn {
        position: relative;
        //transform: translateY(400px);
        //transition-delay: 1.2s;
        //transform: translateX(50%);

        //transition: 2.4s all cubic-bezier(.25, .74, .22, .99);
        //opacity: 0;
      }
    }
  }

  //navigation

  .navigation {
    position: absolute;
    right: ${props => (props.offset ? props.offset : '90')}px;
    top: calc(50% - 10vh);
    transform: translateY(-50%);
    display: inline-block;
    z-index: 1;

    ul {
      display: flex;
      flex-direction: column;

      li {
        cursor: pointer;

        &:first-child {
          margin-bottom: 20px;
          margin-right: 0;
        }

        svg {
          path,
          #Rectangle_5661 {
            //transition: strokeDasharray 0.3s ease-in-out;
            transition: all 1s cubic-bezier(0.25, 0.74, 0.22, 0.99);
          }
        }

        &:hover {
          svg {
            #Rectangle_5661 {
              opacity: 1;

              stroke-dasharray: 140px;
            }

            path {
              stroke: ${hover};
            }
          }
        }
      }
    }
  }

  //responsive
  @media (min-width: 1550px) {
    .slide-inner {
      &__info {
        position: relative;
        overflow: hidden;

        h2 {
          font-size: 80px;
          line-height: 100px;
        }

        .dc-btn {
          a {
            font-size: 22px;
            line-height: 33px;
          }
        }
      }
    }

    .swipper-navigation-slider-custom {
      bottom: 300px;
    }

    .feature-box-container {
      bottom: -150px;
    }

    .feature-box-container {
      .single-item-wrapper {
        min-height: 300px;

        .content {
          min-height: 250px;
        }
      }
    }
  }
  @media (max-width: 992px) {
    .swipper-navigation-slider-custom {
      bottom: 250px;
    }
  }

  @media (max-width: 992px) and (min-width: 768px) {
    height: 100vh;
    .swipper-navigation-slider-custom {
      bottom: 60px;

      .swiper-initialized {
        padding-right: 30%;
      }
    }

    .slide-inner {
      height: 100vh;

      .container {
        top: 45%;
      }
    }

    .feature-box-container {
      display: none;
      position: relative;
      left: 0;
      right: 0;
      bottom: 200px;
      z-index: 999;
      width: calc(100% - 30px);
      margin: 0 auto;

      .single-item-wrapper a .content {
        align-items: flex-start;
      }

      .feature-box-container_item:last-child {
        order: 1;
        flex: 0 0 100%;
        max-width: 100%;
      }

      .feature-box-container_item:first-child {
        order: 2;
        flex: 0 0 100%;
        max-width: 100%;

        .single-item-wrapper {
          border-right: none;
          border-bottom: 1px solid rgba(198, 198, 198, 0.5);
        }
      }

      .feature-box-container_item:nth-of-type(2) {
        order: 3;
        flex: 0 0 50%;
        max-width: 50%;
      }

      .feature-box-container_item:nth-of-type(3) {
        order: 4;
        flex: 0 0 50%;
        max-width: 50%;

        .single-item-wrapper {
          border-right: none;
        }
      }
    }
  }
  @media (max-width: 767px) {
    height: 100vh;

    .mobile {
      display: block;
    }

    .slide-inner {
      height: 100vh;

      .container {
        top: unset;
        transform: unset;
        bottom: 40%;
      }

      &__info {
        position: relative;
        overflow: hidden;

        h2 {
          font-size: 40px;
          line-height: 50px;
          margin: 0 0 30px 0;
          font-weight: 500;
          color: white;
        }

        h1 {
          font-size: 40px;
          line-height: 50px;
          margin: 0 0 30px 0;
          font-weight: 500;
          color: white;
        }

        .dc-btn {
          margin-top: 30px;
        }
      }
    }

    .swipper-navigation-slider-custom {
      padding-left: 15px !important;
      padding-right: 15px !important;
      bottom: 30px;

      .swiper-initialized {
        padding-right: 30%;
      }
    }

    .scrollTo {
      left: 15px;
      right: unset;
      display: inline-block;
      bottom: 20px;
      transform: translateX(0);
    }

    .navigation {
      display: none;
    }

    .feature-box-container {
      display: none;
      position: relative;
      bottom: 150px;

      .feature-box-container_item {
        &:first-child {
          order: 2;
        }

        &:nth-of-type(2) {
          order: 3;
        }

        &:nth-of-type(3) {
          order: 4;
        }

        &:last-child {
          order: 1;

          .content {
          }
        }
      }

      .single-item-wrapper {
        border-right: none;
        border-bottom: 1px solid rgba(198, 198, 198, 0.5);

        .content {
          align-items: flex-start !important;
        }

        &.last {
          border: none;

          .content {
            align-items: center !important;
          }
        }
      }

      .container {
        padding: 0;
      }
    }
  }
`

export default Banner

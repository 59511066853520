import React, { useEffect, useState } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { components } from 'react-select'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { red } from '../../styles/globalStyleVars'
import Button from '../ButtonSubmit'

const CustomDropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <rect
        id="Rectangle_5620"
        data-name="Rectangle 5620"
        width="20"
        height="20"
        fill="#fff"
        opacity="0"
      />
    </svg>
  </components.DropdownIndicator>
)

const GetInTouch = ({ data }) => {
  const [windowWidth, setWindowWidth] = useState(0)
  const [selectType, setSelectType] = useState(null) // Initialize with null or the default selected value
  // const {register, handleSubmit, formState, reset} = useForm({mode: "all"});
  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    reset,
  } = useForm({
    mode: 'all',
  })
  const store = useSelector(state => state.contactReducer)
  // dropdown style
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderRadius: 0,
      color: state.isSelected ? '#FFF' : 'rgba(0,0,0,0.5)',
      backgroundColor: state.isSelected ? '#00A651' : '#]212158',
      margin: 0,
      cursor: 'pointer',
    }),
    menu: (provided, state) => ({
      ...provided,
      color: 'rgba(0,0,0,0.5)',
      backgroundColor: state.isSelected
        ? 'rgba(0,0,0,0)'
        : 'rgba(255,255,255,0)',
      margin: 0,
    }),
    menuList: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#000' : '#FFF',
      borderRadius: 0,
      cursor: 'pointer',
    }),
  }

  // drop down indecator
  const DropdownIndicator = props => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          {props.selectProps.menuIsOpen ? (
            <img src="/images/static/caret-up.svg" alt="" />
          ) : (
            <img src="/images/static/caret-down.svg" alt="" />
          )}
        </components.DropdownIndicator>
      )
    )
  }
  // options
  // const status = [
  //   { value: 'completed', label: 'Completed' },
  //   { value: 'ongoing', label: 'Ongoing' },
  //   { value: 'upcoming', label: 'Upcoming' },
  // ]

  // //--- form submit
  // const success = msg =>
  //   toast.success(msg, {
  //     position: 'top-right',
  //     autoClose: 4000,
  //     closeOnClick: true,
  //     progress: undefined,
  //   })

  // const error = msg =>
  //   toast.error(msg, {
  //     position: 'top-right',
  //     autoClose: 4000,
  //     closeOnClick: true,
  //     progress: undefined,
  //   })

  // const dispatch = useDispatch()

  // const onSubmit = async e => {
  //   // let api_services = 'https://cms.tropicalhomesltd.com/api/post-req-data/form-submit';
  //   let api_services = apiEndPoints.FORM_SUBMIT

  //   var formData = new FormData()
  //   formData.append('name', e?.name)
  //   formData.append('email', e?.email)
  //   formData.append('phone', e?.phone)
  //   formData.append('message', e?.message)
  //   formData.append('project_location', e?.project_location)
  //   formData.append('project_type', selectType?.label)
  //   formData.append('form_id', 'contact-form')

  //   if (e.name !== '' && e.phone !== '') {
  //     await dispatch(postForm([api_services, formData]))
  //   }
  // }

  // useEffect(() => {
  //   if (!store?.loading) {
  //     if (isSubmitSuccessful) {
  //       success('Thank you for your message')
  //       reset()
  //     }
  //   }
  // }, [isSubmitSuccessful, store])

  let count = 0

  const onError = errors => {
    Object.values(errors).forEach(error => {
      count++
    })
    if (count > 0) {
      toast.error('please fill out the correct inputs')
    }
    count = 0
  }

  useEffect(() => {
    // Function to handle window resize
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    // Initial window width
    setWindowWidth(window.innerWidth)

    // Add event listener for window resize
    window.addEventListener('resize', handleResize)

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <StyledComponent>
      <div className="form-area">
        <div className="form-area__form">
          <Container>
            <Row>
              <Col sm={{ span: 10, offset: 1 }} className={'text-center'}>
                <div className="form-area__form__title">
                  <h2 className={'split-up'}>Need More Information?</h2>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={{ span: 10, offset: 1 }}>
                <Form className={'form'}>
                  <Row>
                    <input name={'spam_protector'} type="hidden" />
                    <Col className={'form__item'} md={6} xs={12}>
                      <Form.Label>First Name*</Form.Label>
                      <Form.Control
                        className={
                          errors?.name?.message
                            ? 'has-error form-control-lg'
                            : 'form-control-lg'
                        }
                        {...register('fname', {
                          required: 'First Name is required',
                        })}
                        type="text"
                        placeholder="Enter your first name*"
                      />
                    </Col>
                    <Col className={'form__item'} md={6} xs={12}>
                      <Form.Label>Last Name*</Form.Label>
                      <Form.Control
                        className={
                          errors?.name?.message
                            ? 'has-error form-control-lg'
                            : 'form-control-lg'
                        }
                        {...register('lname', {
                          required: 'Last Name is required',
                        })}
                        type="text"
                        placeholder="Enter your last name*"
                      />
                    </Col>
                    <Col className={'form__item'} md={6} xs={12}>
                      <Form.Label>Phone Number*</Form.Label>
                      <Form.Control
                        className={
                          errors?.phone?.message
                            ? 'has-error form-control-lg'
                            : 'form-control-lg'
                        }
                        {...register('phone', {
                          required: {
                            value: true,
                            message: 'please enter your phone number',
                          },
                          pattern: {
                            value: /^01[0-9]{9}$/,
                            message:
                              'please enter a valid 11 digit phone number',
                          },
                        })}
                        type="text"
                        placeholder="Enter your phone number*"
                      />
                    </Col>
                    <Col className={'form__item'} md={6} xs={12}>
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        {...register('email', {
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: 'please enter a valid email address',
                          },
                        })}
                        type="email"
                        placeholder="Enter your email address"
                      />
                    </Col>
                    <Col className={'form__item'} sm={12}>
                      <Form.Label>Service Interested In</Form.Label>
                      <Form.Control
                        {...register('interested', {
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: 'please enter services interested in.',
                          },
                        })}
                        type="text"
                        placeholder="Enter your service interested in."
                      />
                    </Col>
                    <Col className={'form__item'} sm={4}>
                      <Form.Label>Year</Form.Label>
                      <Form.Control
                        {...register('year', {
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: 'please enter year',
                          },
                        })}
                        type="text"
                        placeholder="Enter your year"
                      />
                    </Col>
                    <Col className={'form__item'} sm={4}>
                      <Form.Label>Make</Form.Label>
                      <Form.Control
                        {...register('make', {
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: 'please enter make',
                          },
                        })}
                        type="text"
                        placeholder="Enter your make"
                      />
                    </Col>
                    <Col className={'form__item'} sm={4}>
                      <Form.Label>Model</Form.Label>
                      <Form.Control
                        {...register('model', {
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: 'please enter model',
                          },
                        })}
                        type="text"
                        placeholder="Enter your model"
                      />
                    </Col>

                    <Col md={12}>
                      <Form.Label>Service Interested In</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={6} // This sets the number of visible rows, adjust as needed
                        style={{ height: '180px' }} // Custom style for the height
                        className={`${
                          errors?.message?.message
                            ? 'has-error form-control-lg'
                            : 'form-control-lg '
                        } cover-letter`}
                        {...register('message')}
                        placeholder="Message"
                      />
                    </Col>

                    <Col className={'form__item'} md={6} xs={12}>
                      <div className="button">
                        <Button
                          width={'100%'}
                          fontSize={'16'}
                          text={'Submit Message'}
                          color={'#FFFFFF'}
                          background={red}
                          hoverBackground={'#3C3C3B'}
                        />
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </StyledComponent>
  )
}

const StyledComponent = styled.section`
  position: relative;

  .select-here .custom__control {
    margin: 0;
  }

  textarea,
  .form-control {
    height: auto;
    background: transparent;
    border-radius: 20px;
    padding: 20px;
    color: #1d3130;
    border-color: #1d3130;

    &::placeholder {
      color: rgba(29, 49, 48, 0.5);
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }

    &:focus {
      border: 1px solid #1d3130;
    }
  }

  .form-area {
    &__form {
      h1 {
        color: #1d3130;
      }
    }
  }

  .form-area input.cover-letter.form-control {
    padding-top: unset;
  }

  .custom__control {
    background-color: transparent;
    border: 1px solid #1d3130 !important;
    box-shadow: none;
    outline: none !important;
    cursor: pointer;
    //margin-bottom: 60px;
    margin-bottom: 20px;
    border-radius: 50px !important;
    height: 60px;
    padding: 0 24px 0 30px;

    .custom__indicator-separator {
      display: none;
    }

    .custom__value-container {
      padding: 0 !important;
      //height: 60px;
    }

    .css-qbdosj-Input {
      margin: 0;
      padding: 0;
    }

    .custom__placeholder,
    .custom__single-value {
      top: 42% !important;
    }

    .custom__indicator {
      top: -3px;
    }

    svg line {
      stroke: #1d3130;
    }

    .custom__single-value {
      color: #1d3130;
      font-size: 15px;
      line-height: 20px;
    }

    .custom__placeholder {
      color: #1d3130;
      font-size: 16px;
      line-height: 24px;
    }

    .custom__value-container {
      padding-left: 0;
    }

    &--is-focused {
    }
  }
`

export default GetInTouch

import React from 'react';
import styled from "styled-components";


const FloatingIcon = () => {
    return (
        <StyledComponent className={"float-icon"}>
            <div className="icon">
                <ul>
                    <li><a target={"_blank"} href="https://www.instagram.com/elitetintusa/?igsh=MTdldWM1bTJpYTY1&utm_source=qr" rel="noreferrer"><img
                        height={23} width={23}
                        src="/images/static/Instagram_icon.png.png" alt=""/></a>
                    </li>
                    <li><a target={"_blank"} href="https://wa.me/message/OJRIWYHBJPQXI1" rel="noreferrer"><img
                        height={23} width={23}
                        src="/images/static/whatsapp.png" alt=""/></a>
                    </li>
                    <li><a target={"_blank"} href="https://www.messenger.com/t/1028624343830718" rel="noreferrer"><img
                        eight={23} width={23} src="/images/static/messenger.svg" alt=""/></a></li>
                    <li><a target={"_blank"} href="https://www.tiktok.com/@elitetintusa?_t=8pdIlhNz1Y3&_r=1"
                           rel="noreferrer"><img eight={23} width={23} src="/images/static/tik-tok.svg"
                                                 alt="tiktok"/></a></li>
                </ul>
            </div>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    .icon {
        position: fixed;
        right: 24px;
        bottom: 75px;
        z-index: 9;

        li {
            a {
                height: 45px;
                width: 45px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #fff;

                img {
                    height: 23px;
                }
            }

            &:nth-of-type(3) {
                margin-bottom: 20px;
            }

            &:nth-of-type(2) {
                margin-bottom: 20px;
            }

            &:nth-of-type(1) {
                margin-bottom: 20px;
            }
        }
    }
`;

export default FloatingIcon;

import { motion } from 'framer-motion'
import React, { useEffect } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { apiEndPoints } from '../../api/network/apiEndPoints'
import { ApiParam } from '../../api/network/apiParams'
import { fetchAbout } from '../../api/redux/about'
import { PageAnimation } from '../../components/animations/PageAnimation'
import Note from '../../components/contact/Note'
import DisclaimerText from '../../components/DisclaimerText/DisclaimerText'
import InnerBanner from '../../components/InnerBanner'

const Disclaimer = ({ offset }) => {
  const dispatch = useDispatch()

  // api call
  useEffect(() => {
    let api_url = apiEndPoints.SECTIONS

    let api_params = {
      [ApiParam.TYPE]: 'slug',
      [ApiParam.VALUE]: 'about',
      [ApiParam.GET_SECTION]: 'yes',
      [ApiParam.IMAGE]: 'yes',
      [ApiParam.POST]: 'yes',
    }

    dispatch(fetchAbout([api_url, api_params]))
  }, [])

  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Disclaimer | Elite Tint</title>
        <meta
          name="description"
          content="Read the disclaimer for Elite Tint, outlining the terms and conditions regarding the information provided on our website, services, and warranties for window tinting installations."
        />
      </Helmet>

      <motion.div
        key={`1w83`}
        className="page-loader"
        exit="exit"
        animate="anim"
        variants={PageAnimation}
        initial="init"
      ></motion.div>

      <StyledComponent>
        <InnerBanner
          img={'/images/dynamic/disclaimer/disclaimer.jpg'}
          srcSm={'/images/dynamic/disclaimer/disclaimer.jpg'}
        />
        <DisclaimerText />
        <Note
          title={'Note'}
          desc={
            'Julien Window Tinting in North East, MD, can be reached at <a href="tel:4102878468">Call 410-287-8468</a>\n to learn more or to schedule a window tinting appointment.'
          }
        />
      </StyledComponent>
    </HelmetProvider>
  )
}

const StyledComponent = styled.section``

export default Disclaimer

import React from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import styled from 'styled-components'
import Button from '../ButtonSubmit'
import {red} from "../../styles/globalStyleVars";
const GetInTouchElite = () => {
  return (
    <StyledComponent>
      <div className="form-area">
        <div className="form-area__form">
          <Container>
            <Row>
              <Col sm={{ span: 10, offset: 1 }} className={'text-center'}>
                <div className="form-area__form__title">
                  <h2 className={'split-up'}>Need More Information?</h2>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={{ span: 10, offset: 1 }}>
                <Form className={'form'}>
                  <Row>
                    <input name={'spam_protector'} type="hidden" />
                    <Col className={'form__item'} md={6} xs={12}>
                      <Form.Label>First Name*</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter your first name*"
                      />
                    </Col>
                    <Col className={'form__item'} md={6} xs={12}>
                      <Form.Label>Last Name*</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter your last name*"
                      />
                    </Col>
                    <Col className={'form__item'} md={6} xs={12}>
                      <Form.Label>Phone Number*</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter your phone number*"
                      />
                    </Col>
                    <Col className={'form__item'} md={6} xs={12}>
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter your email address"
                      />
                    </Col>
                    <Col className={'form__item'} sm={12}>
                      <Form.Label>Service Interested In</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter your service interested in."
                      />
                    </Col>
                    <Col md={12}>
                      <Form.Label>Service Interested In</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={6} // This sets the number of visible rows, adjust as needed
                        style={{ height: '180px' }} // Custom style for the height
                        placeholder="Message"
                      />
                    </Col>

                    <Col className={'form__item'} md={6} xs={12}>
                      <div className="button">
                        <Button
                            width={'100%'}
                            fontSize={'16'}
                            text={'Submit Message'}
                            color={'#FFFFFF'}
                            background={red}
                            hoverBackground={'#3C3C3B'}
                        />
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </StyledComponent>
  )
}

const StyledComponent = styled.section`
  position: relative;

  .select-here .custom__control {
    margin: 0;
  }

  textarea,
  .form-control {
    height: auto;
    background: transparent;
    border-radius: 20px;
    padding: 20px;
    color: #1d3130;
    border-color: #1d3130;

    &::placeholder {
      color: rgba(29, 49, 48, 0.5);
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }

    &:focus {
      border: 1px solid #1d3130;
    }
  }

  .form-area {
    &__form {
      h1 {
        color: #1d3130;
      }
    }
  }

  .form-area input.cover-letter.form-control {
    padding-top: unset;
  }

  .custom__control {
    background-color: transparent;
    border: 1px solid #1d3130 !important;
    box-shadow: none;
    outline: none !important;
    cursor: pointer;
    //margin-bottom: 60px;
    margin-bottom: 20px;
    border-radius: 50px !important;
    height: 60px;
    padding: 0 24px 0 30px;

    .custom__indicator-separator {
      display: none;
    }

    .custom__value-container {
      padding: 0 !important;
      //height: 60px;
    }

    .css-qbdosj-Input {
      margin: 0;
      padding: 0;
    }

    .custom__placeholder,
    .custom__single-value {
      top: 42% !important;
    }

    .custom__indicator {
      top: -3px;
    }

    svg line {
      stroke: #1d3130;
    }

    .custom__single-value {
      color: #1d3130;
      font-size: 15px;
      line-height: 20px;
    }

    .custom__placeholder {
      color: #1d3130;
      font-size: 16px;
      line-height: 24px;
    }

    .custom__value-container {
      padding-left: 0;
    }

    &--is-focused {
    }
  }
`

export default GetInTouchElite

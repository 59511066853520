import { motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import styled from 'styled-components'
import { PageAnimation } from '../../components/animations/PageAnimation'
import GetInTouch from '../../components/contact/GetInTouch'
import CTAV3 from '../../components/CTA-v3'
import About from '../../components/home/About'
import Banner from '../../components/home/Banner'
import HomeServices from '../../components/home/HomeServices'
import TestimonialSlider from '../../components/TestimonialSlider'
import USP from '../../components/USP'

const MyComponent = () => {
  const [offset, setOffset] = useState('90')
  const [winWidth, setWindowWidth] = useState(0)

  // get offset for container
  useEffect(() => {
    if (window.innerWidth > 767) {
      setOffset(document.querySelector('.container').offsetLeft + 30)
    }
  })

  useEffect(() => {
    const updateOffset = () => {
      if (window.innerWidth > 767) {
        const container = document.querySelector('.container')
        if (container) {
          setOffset(container.offsetLeft + 15)
        }
      }
    }

    // Add an event listener for the DOMContentLoaded event
    document.addEventListener('DOMContentLoaded', updateOffset)

    // Add event listener to update offset on window resize
    window.addEventListener('resize', updateOffset)
    window.addEventListener('load', updateOffset)

    return () => {
      document.removeEventListener('DOMContentLoaded', updateOffset)
      window.removeEventListener('resize', updateOffset)
      window.removeEventListener('load', updateOffset)
    }
  }, [])

  useEffect(() => {
    // Function to handle window resize
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    // Initial window width
    setWindowWidth(window.innerWidth)

    // Add event listener for window resize
    window.addEventListener('resize', handleResize)

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Elite Tinting | Home</title>
        <meta
          name="description"
          content="Elite Tinting offers professional window tinting services for automotive, residential, commercial, and marine applications. Contact us to learn how our expert team can enhance your privacy, energy efficiency, and style with high-quality window films."
        />
      </Helmet>

      <motion.div
        key={`1w83`}
        className="page-loader"
        exit="exit"
        animate="anim"
        variants={PageAnimation}
        initial="init"
      ></motion.div>

      <StyledComponent>
        <Banner />
        <About />
        <HomeServices />
        <USP offset={offset} />
        <GetInTouch />
        <TestimonialSlider />
        <CTAV3
          bgImage={'/images/dynamic/home/cta.jpg'}
          hideBtn
          title={'Call Our Team Today'}
          subtitle={
            'Finnkits Window Tinting in North East, MD, can be reached at 410-287-8468 to learn more about our window tinting services by a team of professionals.'
          }
          offset={offset}
          winWidth={winWidth}
        />
      </StyledComponent>
    </HelmetProvider>
  )
}

const StyledComponent = styled.section``

export default MyComponent

import React from 'react'
import styled from 'styled-components'

const TableList = () => {
  return (
    <StyledTableList>
      <div className="table-container">
        <h2>VORTEX-CERAMIC FILM (LIFETIME WARRANTY)</h2>
        <table className="pricing-table">
          <thead>
            <tr>
              <th>Service</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>BROW ONLY</td>
              <td>$40</td>
            </tr>
            <tr>
              <td>FRONT DOORS</td>
              <td>$100</td>
            </tr>
            <tr>
              <td>FRONT DOORS W/BROW</td>
              <td>$140</td>
            </tr>
            <tr>
              <td>CAR/COMPACT SUV'S</td>
              <td>$250</td>
            </tr>
            <tr>
              <td>CAR/COMPACT SUV'S W/BROW</td>
              <td>$290</td>
            </tr>
            <tr>
              <td>CAR/COMPACT SUV'S W/FULL WINDSHIELD</td>
              <td>$400</td>
            </tr>
            <tr>
              <td>FULL SIZE SUV & MINI VANS</td>
              <td>$300</td>
            </tr>
            <tr>
              <td>FULL SIZE SUV & MINI VANS W/BROW</td>
              <td>$340</td>
            </tr>
            <tr>
              <td>FULL SIZE SUV & MINI VANS W/FULL WINDSHIELD</td>
              <td>$450</td>
            </tr>
            <tr>
              <td>TRUCK (SINGLE/REGULAR CAB)</td>
              <td>$150</td>
            </tr>
            <tr>
              <td>TRUCK (SINGLE/REGULAR CAB) W/BROW</td>
              <td>$190</td>
            </tr>
            <tr>
              <td>TRUCK (SINGLE/REG CAB) W/FULL WINDSHIELD</td>
              <td>$300</td>
            </tr>
            <tr>
              <td>TRUCK (EXTENDED/CREW CAB)</td>
              <td>$200</td>
            </tr>
            <tr>
              <td>TRUCK (EXTENDED/CREW CAB) W/BROW</td>
              <td>$240</td>
            </tr>
            <tr>
              <td>TRUCK (EXT/CREW CAB) W/FULL WINDSHIELD</td>
              <td>$350</td>
            </tr>
            <tr>
              <td>FULL WINDSHIELD</td>
              <td>$150</td>
            </tr>
            <tr>
              <td>SINGLE DOOR WINDOW</td>
              <td>$50</td>
            </tr>
            <tr>
              <td>SUNROOF ONLY (STANDARD SIZE)</td>
              <td>$40</td>
            </tr>
            <tr>
              <td>SUNROOF ONLY (PANORAMIC)</td>
              <td>$100</td>
            </tr>
            <tr>
              <td>REAR WINDOW</td>
              <td>$150</td>
            </tr>
          </tbody>
        </table>
      </div>
    </StyledTableList>
  )
}

const StyledTableList = styled.section`
  margin-top: 40px;
  margin-bottom: 40px;

  h2 {
    font-size: 28px;
    text-align: center;
    padding-bottom: 30px;
  }

  .table-container {
    width: 80%;
    margin: 0 auto;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }

  .pricing-table {
    width: 100%;
    border-collapse: collapse;
  }

  .pricing-table thead {
    background-color: #ddd;
  }

  .pricing-table th,
  .pricing-table td {
    padding: 12px 16px;
    text-align: left;
  }

  .pricing-table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  .pricing-table tbody tr:hover {
    background-color: #e9e9e9;
  }

  .pricing-table th {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 14px;
  }

  .pricing-table td {
    font-size: 14px;
    color: #333;
  }

  .pricing-table td:last-child {
    text-align: right;
  }
`

export default TableList
